@import '../../style/themes/index';
@import '../../style/mixins/index';

@spin-prefix-cls: ~'@{ant-prefix}-spin';
@spin-dot-default: @text-color-secondary;

.@{spin-prefix-cls} {
  .reset-component();

  position: absolute;
  display: none;
  color: @primary-color;
  text-align: center;
  vertical-align: middle;
  opacity: 0;
  transition: transform 0.3s @ease-in-out-circ;

  &-spinning {
    position: static;
    display: inline-block;
    opacity: 1;
  }

  &-nested-loading {
    position: relative;
    > div > .@{spin-prefix-cls} {
      position: absolute;
      top: 0;
      left: 0;
      z-index: 4;
      display: block;
      width: 100%;
      height: 100%;
      max-height: 400px;
      .@{spin-prefix-cls}-dot {
        position: absolute;
        top: 50%;
        left: 50%;
        margin: -(@spin-dot-size / 2);
      }
      .@{spin-prefix-cls}-text {
        position: absolute;
        top: 50%;
        width: 100%;
        padding-top: ((@spin-dot-size - @font-size-base) / 2) + 2px;
        text-shadow: 0 1px 2px @shadow-color-inverse;
      }
      &.@{spin-prefix-cls}-show-text .@{spin-prefix-cls}-dot {
        margin-top: -(@spin-dot-size / 2) - 10px;
      }
    }

    > div > .@{spin-prefix-cls}-sm {
      .@{spin-prefix-cls}-dot {
        margin: -(@spin-dot-size-sm / 2);
      }
      .@{spin-prefix-cls}-text {
        padding-top: ((@spin-dot-size-sm - @font-size-base) / 2) + 2px;
      }
      &.@{spin-prefix-cls}-show-text .@{spin-prefix-cls}-dot {
        margin-top: -(@spin-dot-size-sm / 2) - 10px;
      }
    }

    > div > .@{spin-prefix-cls}-lg {
      .@{spin-prefix-cls}-dot {
        margin: -(@spin-dot-size-lg / 2);
      }
      .@{spin-prefix-cls}-text {
        padding-top: ((@spin-dot-size-lg - @font-size-base) / 2) + 2px;
      }
      &.@{spin-prefix-cls}-show-text .@{spin-prefix-cls}-dot {
        margin-top: -(@spin-dot-size-lg / 2) - 10px;
      }
    }
  }

  &-container {
    position: relative;
    transition: opacity 0.3s;

    &::after {
      position: absolute;
      top: 0;
      right: 0;
      bottom: 0;
      left: 0;
      z-index: 10;
      display: ~'none \9';
      width: 100%;
      height: 100%;
      background: @component-background;
      opacity: 0;
      transition: all 0.3s;
      content: '';
      pointer-events: none;
    }
  }

  &-blur {
    clear: both;
    overflow: hidden;
    opacity: 0.5;
    user-select: none;
    pointer-events: none;

    &::after {
      opacity: 0.4;
      pointer-events: auto;
    }
  }

  // tip
  // ------------------------------
  &-tip {
    color: @spin-dot-default;
  }

  // dots
  // ------------------------------

  &-dot {
    position: relative;
    display: inline-block;
    font-size: @spin-dot-size;

    .square(1em);

    &-item {
      position: absolute;
      display: block;
      width: 9px;
      height: 9px;
      background-color: @primary-color;
      border-radius: 100%;
      transform: scale(0.75);
      transform-origin: 50% 50%;
      opacity: 0.3;
      animation: antSpinMove 1s infinite linear alternate;

      &:nth-child(1) {
        top: 0;
        left: 0;
      }
      &:nth-child(2) {
        top: 0;
        right: 0;
        animation-delay: 0.4s;
      }
      &:nth-child(3) {
        right: 0;
        bottom: 0;
        animation-delay: 0.8s;
      }
      &:nth-child(4) {
        bottom: 0;
        left: 0;
        animation-delay: 1.2s;
      }
    }

    &-spin {
      transform: rotate(45deg);
      animation: antRotate 1.2s infinite linear;
    }
  }

  // Sizes
  // ------------------------------

  // small
  &-sm &-dot {
    font-size: @spin-dot-size-sm;

    i {
      width: 6px;
      height: 6px;
    }
  }

  // large
  &-lg &-dot {
    font-size: @spin-dot-size-lg;

    i {
      width: 14px;
      height: 14px;
    }
  }

  &&-show-text &-text {
    display: block;
  }
}

@media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
  /* IE10+ */
  .@{spin-prefix-cls}-blur {
    background: @component-background;
    opacity: 0.5;
  }
}

@keyframes antSpinMove {
  to {
    opacity: 1;
  }
}

@keyframes antRotate {
  to {
    transform: rotate(405deg);
  }
}

@import './rtl';

@primary-color: #d63031;@body-background: #ffffff;@font-family: Montserrat;@code-family: Montserrat;@text-color: #111111;@heading-color: #111111;@font-size-base: 15px;@btn-font-weight: 600;@btn-border-radius-base: 3px;@btn-height-base: 45px;@btn-height-lg: 50px;@btn-height-sm: 35px;@input-height-base: 50px;@input-height-lg: 54px;@input-height-sm: 40px;@input-border-color: #dddddd;@input-placeholder-color: #777777;@input-color: #222222;@select-border-color: #dddddd;@select-item-selected-font-weight: 500;@select-dropdown-height: 34px;@select-item-selected-bg: @primary-color;@select-item-selected-color: #fff;@select-dropdown-font-size: 14px;@table-header-bg: #d63031;@table-header-color: #fff;